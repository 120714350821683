import React, { CSSProperties } from "react";
import ColorGuide from "../colorGuide"

type Props = {

}

type State = {
  sidebarOpen: boolean;
}


class Sidebar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.setState({sidebarOpen: false});
  }

  sidebarOpen(): any {
    this.setState({sidebarOpen: true});
  }

  sidebarClose(): any {
    this.setState({sidebarOpen: false});
  }

  render() {

    // Additional styles
    // This styles must be seperate - and not in the css classes - because otherwise it won't work. Happy CSS!
    const sidebarStyle: CSSProperties = { 
      'display': this.state?.sidebarOpen ? 'block' : 'none',
      'zIndex': 3,
      'width': '300px',
      'fontWeight': 700,
      'backgroundColor': `${ColorGuide.color4LightGreen}`
    };

    const closeMenuStyle: CSSProperties = {
      'width': '100%',
      'fontSize': '22px'
    }
    
    const overlayStyle: CSSProperties = { 
      'cursor': 'pointer',
      'display': this.state?.sidebarOpen ? 'block' : 'none'
    };

    const topMenuStyle: CSSProperties = { 
      'backgroundColor': `${ColorGuide.color4LightGreen}`,
    };


    // CSS classes used on elemets below
    const sidebarClasses = `w3-sidebar w3-collapse w3-top w3-large w3-padding`;
    const closeMenuClasses = `w3-button w3-hide-large w3-display-topleft`;
    const overlayClasses = `w3-overlay w3-hide-large`;

    // Appearance of sidebar
    return (
    <div>
      
      {/* Sidebar - hidden on small screens */}
      <nav className={sidebarClasses} style={sidebarStyle} id="mySidebar"><br></br>
        <button className={closeMenuClasses} style={closeMenuStyle} onClick={() => this.sidebarClose()}>Close Menu</button>
        <div className="w3-container">
          <h3 className="w3-padding-64"><b>Impuls.Ing<br></br>GmbH</b></h3>
        </div>

        <div className="w3-bar-block">
          <a href="home" className="w3-bar-item w3-button w3-hover-white" onClick={() => this.sidebarClose()}>Home</a> 
          <a href="sensor" className="w3-bar-item w3-button w3-hover-white" onClick={() => this.sidebarClose()}>Sensor</a> 
          <a href="https://www.impulsing.ch" className="w3-bar-item w3-button w3-hover-white" onClick={() => this.sidebarClose()}>Impuls.Ing</a> 
        </div>
      </nav>

      {/* Top menu on small screens */}
      <header className="w3-container w3-top w3-hide-large w3-xlarge w3-padding" style={topMenuStyle}>
        <button className="w3-button w3-margin-right" onClick={() => this.sidebarOpen()}>☰</button>
        <span>Impuls.Ing GmbH</span>
      </header>

      {/* Overlay effect when opening sidebar on small screens */}
      <div className={overlayClasses} style={overlayStyle} title="close side menu" id="myOverlay" onClick={() => this.sidebarClose()}></div>

    </div>
    );
  };
};

export default Sidebar;
