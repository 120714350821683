import React from "react";

type Props = {
  label: string;
  materialIcon: string;
  value: number; 
  unit: string;
  timeString: string;
}

class SmallStats extends React.Component<Props> {

  static defaultProps: Props = {
    label: "Label?",
    materialIcon: "help_outline",
    value: 0, 
    unit: "",
    timeString: "",
  };

  render() {
    // Get values from props
    const { label, materialIcon, value, unit, timeString} = this.props;
    
    return (
      <div className="w3-card-4 w3-margin-right" style={{display: 'inline-block', minWidth: '230px'}}>

        <header className="w3-container w3-light-grey w3-center">
          <h3>{label}</h3>
        </header>

        <div className="w3-cell-row w3-padding w3-center">
          <div className="w3-container w3-cell w3-cell-middle w3-circle">
            <i className="material-icons w3-xxlarge w3-text-dark-grey">{materialIcon}</i>
          </div>
          <div className="w3-container w3-cell w3-cell-middle">
            <h2>{value}{unit}</h2>
          </div>
        </div>

        <div className="w3-container w3-padding w3-light-grey"><span className="w3-opacity">{timeString}</span></div>

      </div>
    );
  }
}

export default SmallStats;
