

class ColorGuide {

    static color0Green: string = '#95a844';
    static color1Darkgrey: string = '#44494f';
    static color2Darkgreengray: string = '#8f997a';
    static color4LightGreen: string = '#dedabb';   
}

export default ColorGuide;