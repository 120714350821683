import React from 'react';
import ColorGuide from "../colorGuide"



function DatenschutzView() {

  return (
    <div style={{minHeight: '80vh'}}>

      <div className="w3-container" style={{'margin':'80px 0px 0px 0px'}} id="showcase">
        <h1 className="w3-xxxlarge"><b>Datenschutzerklärung</b></h1>
        <hr className="w3-round" style={{'width':'50px','border':`5px solid ${ColorGuide.color2Darkgreengray}`}}></hr>
      </div>

      <div className="w3-container w3-margin-top">
        <h1 className="w3-xlarge"><b>Erhebung, Verarbeitung und Nutzung personenbezogener Daten</b></h1>
      </div>

      <div className="w3-container"> 
        <p>Bei einem Besuch dieser Website erheben wir keine personenbezogenen Daten von Ihnen.</p>
      </div>

      <div className="w3-container w3-margin-top">
        <h1 className="w3-xlarge"><b>Verwendung von Cookies</b></h1>
      </div>
      
      <div className="w3-container"> 
        <p>Diese Seite verwendet keine Cookies.</p>
      </div>

      <div className="w3-container w3-margin-top">
        <h1 className="w3-xlarge"><b>Server-Log-Dateien</b></h1>
      </div>

      <div className="w3-container"> 
        <p>
          Wie bei jeder Verbindung mit einem Webserver protokolliert und speichert der Server unseres Webhosting-Anbieters hosttech in Richterswil, Schweiz, bestimmte technische Daten. 
          Zu diesen Daten gehören die IP-Adresse und das Betriebssystem Ihres Geräts, die Daten, die Zugriffszeit, die Art des Browsers sowie die Browser-Anfrage inklusive der Herkunft der Anfrage (Referrer). 
          Dies ist aus technischen Gründen erforderlich, um Ihnen unsere Website zur Verfügung zu stellen. 
          Der Webhosting-Anbieter schützt diese Daten mit technischen und organisatorischen Massnahmen vor unerlaubten Zugriffen und gibt sie nicht an Dritte weiter. 
          Soweit wir dabei personenbezogene Daten verarbeiten, tun wir dies aufgrund unseres Interesses, Ihnen die bestmögliche Nutzererfahrung zu bieten und die Sicherheit und Stabilität unserer Systeme zu gewährleisten.
        </p>
      </div>
      
      <div className="w3-container w3-margin-top">
        <h1 className="w3-xlarge"><b>Ihre Rechte</b></h1>
      </div>

      <div className="w3-container"> 
        <p>Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu.</p>
        <p>Kontaktdaten:</p>
        <p>
          Impuls.Ing GmbH<br></br>
          Wassergrabe 6<br></br>
          6210 Sursee<br></br>
          Schweiz<br></br>
          Tel: +41 41 508 16 24<br></br>
          <a href="mailto:info@impulsing.ch">info@impulsing.ch</a>
        </p>
      </div>


    </div>
  );
}
  
  export default DatenschutzView;