import React from 'react';
import ColorGuide from "../colorGuide"
import btCardImage from "./btCard.jpg"

function HomeView() {
  return (
    <div style={{minHeight: '80vh'}}>

      <div className="w3-container" style={{'margin':'80px 0px 0px 0px'}} id="showcase">
        <h1 className="w3-jumbo"><b>Bluetooth Card</b></h1>
        <h1 className="w3-xxxlarge"><b>Showcase.</b></h1>
        <hr className="w3-round" style={{'width':'50px','border':`5px solid ${ColorGuide.color2Darkgreengray}`}}></hr>
      </div>

      <div className="w3-container">
        <p>Willkommen auf der Webseite unseres Bluetooht Beispielprojektes, der Bluetooth Card. Wir sind ein junges Ingenieurbüro und möchten Ihnen eine einfache Möglichkeit bieten, wie sie ihre Geräte ansprechend mit einem Smartphone steuern können!</p>
        <img src={btCardImage} style={{width: '80%', maxWidth: '1000px', marginLeft: '8%'}} alt="Foto Bluetooth Card" />
        <p>Um sich mit der Bluetooth Card verbinden zu können, müssen Sie keine dedizierte App von Ihrem App- oder Play Store herunterladen. Diese Seite ist die App. Der nächste Abschnitt erklärt die Vorbereitungen zum Verbindungsaufbau. Anschliessend haben Sie die Möglichkeit, die Temperatur und Feuchtigkeitsdaten des auf der Bluetooth Card befindlichen Sensors auszulesen.</p>
        <p>Wir wünschen Ihnen viel Spass!</p>
      </div>

      <div className="w3-container w3-margin-top">
        <h1 className="w3-xlarge"><b>Vorbereitung</b></h1>
      </div>

      <div className="w3-container"> 
        <p>Damit Sie sich mit der Bluetooth Card verbinden können müssen Sie nur wenige Vorbeitungen treffen. Sie benötigen einen Bluetooth fähigen Webbrowser und Bluetooth Schnittstelle. Je nach Gerät haben Sie diese Dinge meist schon bereit:</p>          
        <div>
          <ul>
            <li>Bluetooth aktivieren</li>
            <li>Falls Sie ein Android Smartphone verwenden:</li>
              <ul>
                <li>Der verfügbare Webbrowser unterstützt meist Bluetooth. Sie müssen nichts weiter tun.</li>
              </ul>
            <li>Falls Sie ein Apple Iphone oder MacBook verwenden:</li>
              <ul>
                <li>Der Safari Webbrowser unterstützt kein Bluetooth.</li>
                <li>Installieren Sie den <a href="https://apps.apple.com/us/app/bluefy-web-ble-browser/id1492822055" target="_blank" rel="noreferrer">Bluefy Webbrowser</a> oder einen anderen Bluetooth fähigen Webbrowser.</li>
                <li>Rufen Sie diese Webseite mit dem neuen Webbrowser auf.</li>
              </ul>
            <li>Falls Sie einen Desktop PC oder Notebook verwenden:</li>
              <ul>
                <li>Möglicherweise haben Sie keine Bluetooth Schnittstelle.</li>
                <li>Diese kann mit einem USB-Bluetooth Adapter nachgerüstet werden.</li>
              </ul>
            <li>Nun können Sie auf der <a href="/sensor">Sensor</a> Seite eine Verbindung herstellen.</li>
          </ul>
        </div>
      </div>

    </div>
  );
}
  
  export default HomeView;