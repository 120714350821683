import React, { useState, CSSProperties } from 'react';
import SmallStats from "../components/SmallStats";
import ColorGuide from "../colorGuide"
import SensorChart from "../components/SensorChart"

/*************************************************************/
//! Reference to the sensor chart, used to add data to plot
let tempChart: SensorChart | null;
let humidityChart: SensorChart | null;

/*************************************************************/
//! Bluetooth related variables
let deviceConnected: BluetoothDevice | null;
let deviceConnectedSince: string;
let serviceSensor: BluetoothRemoteGATTService | undefined;
let characteristicTemperature: BluetoothRemoteGATTCharacteristic | undefined;
let characteristicHumidity: BluetoothRemoteGATTCharacteristic | undefined;

/*************************************************************/
//! Bluetooth UUIDs definitions
const sensorServiceUuid = '394ca90f-0f28-46d9-b79f-d968f532d19b';
const temperatureCharacterisitcUuid = '59df843f-fafa-4d02-bd96-dbe709a3330a';
const humidityCharacterisitcUuid = '59df843f-fafa-4d02-bd96-dbe709a3330b';

/*************************************************************/
//! Callback functions to update view from received bluetooth data
let temperatureCallback: (x:number) => void;
let temperatureTimestamp: string = "--:--:--";
let humidityCallback: (x:number) => void;
let humidityTimestamp: string = "--:--:--";
let isConnectedCallback: (x:boolean) => void;

/**
 * This function opens the connect window and lists all matching bluetooth devices.
 * The user then can select which device he wants to connecto to.
 */
function btListDevices() {           

  // Return immediately if we don't have any bluetooth interface.
  navigator.bluetooth.getAvailability().then(available => {
    if (false === available) {
      window.alert("Bluetooth wird auf diesem Gerät nicht unterstützt.");
        return;  
    }
  });
  
  // Otherwise we listen for matching devices and connect to the selected device.
  navigator.bluetooth.requestDevice({
    filters: [{
      namePrefix : 'btCard'
    }],
    optionalServices: [sensorServiceUuid]
    })
    .then(device => { 
      console.log(`connected to '${device.name}'`);
      deviceConnected = device;
      deviceConnected.ongattserverdisconnected = handleDisconnectFromCard;
      deviceConnectedSince = new Date().toLocaleDateString() + " / " + new Date().toLocaleTimeString();
      isConnectedCallback(true);
      return device.gatt?.connect();
    })
    .then(server => {
      console.log(`connected to gatt server`);
      return server?.getPrimaryService(sensorServiceUuid);
    })
    .then(service => {
      console.log(`got sensor service from gatt server`);
      serviceSensor = service;
      return service?.getCharacteristic(temperatureCharacterisitcUuid);
    })
    .then(characteristic => {
      console.log(`got temperature characteristic`);
      characteristicTemperature = characteristic;
      console.log(`add temperate characteristic event listener`);
      characteristic?.addEventListener('characteristicvaluechanged', handleTemperatureChanged);
      // Return again the sensor service so we can get the next characteristic
      return serviceSensor;
    })
    .then(service => {
      return service?.getCharacteristic(humidityCharacterisitcUuid);
    })
    .then(characteristic => {
      console.log(`got humidity characteristic`);
      characteristicHumidity = characteristic;
      console.log(`add humidity characteristic event listener`);
      characteristic?.addEventListener('characteristicvaluechanged', handleHumidityChanged);
    })
    .then(() =>  {
      console.log(`we have all characterisitcs. Start notifications.`);
      characteristicTemperature?.startNotifications();
      characteristicHumidity?.startNotifications();
    })
    .catch(error => { console.log(error); })
    .finally(() => {  });
}

/**
 * Disconnect from the bluetooth device
 */
function disconnect() {
  deviceConnected?.gatt?.disconnect();
  deviceConnected = null;
  isConnectedCallback(false);
}

/**
 * This function is called if we receive a new temperature value from the bluetooth device
 * @param this Characteristic which caused this notification
 * @param event Notification event
 */
function handleTemperatureChanged(this: BluetoothRemoteGATTCharacteristic, event: Event) {
  let raw: number = this.value?.getUint32(0) || 0;
  let val: number = Math.round(raw / 100) / 10;
  temperatureCallback(val);
  temperatureTimestamp = new Date().toLocaleTimeString();
  tempChart?.dataAddValue(val);
}

/**
 * This function is called if we receive a new humidity value from the bluetooth device
 * @param this Characteristic which caused this notification
 * @param event Notification event
 */
function handleHumidityChanged(this: BluetoothRemoteGATTCharacteristic, event: Event) {
  let raw = this.value?.getUint32(0) || 0;
  let val: number = Math.round(raw / 100) / 10;
  humidityCallback(val);
  humidityTimestamp = new Date().toLocaleTimeString();
  humidityChart?.dataAddValue(val);
}

/**
 * This function is called in case the bluetooth device disconnects
 * @param this Device which caused this notification
 * @param ev Notification event
 */
function handleDisconnectFromCard(this: BluetoothDevice, ev: Event) {
  console.log(`card has disconnected!`);
  deviceConnected = null;
  isConnectedCallback(false);
}


function SensorView() {

  // This are the state variables
  const [temperature, setTemperature] = useState(0)
  const [humidity, setHumidity] = useState(0);
  const [isConnected, setIsConnected] = useState(false);

  // Register state setter functions to the outer callback function, so we can 
  // use these functions outside of this function.
  temperatureCallback = setTemperature;
  humidityCallback = setHumidity;
  isConnectedCallback = setIsConnected;

  // Defintions used for the connection block part
  const connectionString = (!isConnected) ? 
    `Nicht verbunden. Klicke auf 'Verbinde Bluetooth Card' um zu beginnen.` : 
    `Verbunden mit '${deviceConnected?.name}' seit ${deviceConnectedSince}`;

  const connectionDivStyle: CSSProperties = { 'backgroundColor': (!isConnected) ? 'rgba(244, 67, 54, 0.5)' : 'rgba(76, 175, 80, 0.5)'};
  const connectBtnStyle: CSSProperties = { 'display': (!isConnected) ? 'inline' : 'none' };
  const disconnectBtnStyle: CSSProperties = { 'display': (!isConnected) ? 'none' : 'inline' };

  return (
    <div style={{minHeight: '80vh'}}>

      <div className="w3-container" style={{'margin':'80px 0px 0px 0px'}}>
        <h1 className="w3-xxxlarge"><b>Sensor</b></h1>
        <hr className="w3-round" style={{'width':'50px','border':`5px solid ${ColorGuide.color2Darkgreengray}`}}></hr>
      </div>

      <div className="w3-container"> 
        <p>Auf dieser Seite sehen Sie die aktuellen Temperatur- und Feuchtigkeitswerte des Sensors. Unterhalb des jeweiligen Wertes ist ersichtlich, wann dieser Wert gemessen wurde. Des Weiteren werden die letzten 200 Messwerte in einem Linendiagramm dargestellt.</p>
        <p>Zu Begin müssen Sie sich mit der Bluetooth Card verbinden. Klicken Sie dazu unten auf die Schaltfläche 'Verbinde Bluetooth Card'. Das Verbinden kann beim ersten mal einige Sekunden dauern, da ein Pairing durchgeführt wird. Ist die Bluetooth Card erfolgreich verbunden, beginnt diese Sensorwerte zu übermitteln.</p>
        <p>Um die Messung und Übertragung zu beenden, klicken Sie auf 'Verbindung trennen'. Auch wenn Sie nicht trennen, wird das die Bluetooth Card nach einer Stunde automatisch tun. Sie schaltet sich dann automatisch aus um Energie zu sparen. Durch klicken auf den 'Pair' Knopf auf der Karte wird diese wieder aufgeweckt. </p>
      </div>

      <div className="w3-container w3-margin-top">
        <h1 className="w3-xlarge"><b>Verbindung</b></h1>
      </div>

      <div className="w3-container w3-margin-left w3-margin-right" style={connectionDivStyle}>
        <p>{connectionString}</p>
        <p>
          <button onClick={btListDevices} style={connectBtnStyle}>Verbinde Bluethooth Card</button>
          <button onClick={disconnect} style={disconnectBtnStyle}>Verbindung trennen</button>
        </p>
      </div>

      <div className="w3-container w3-margin-top">
        <h1 className="w3-xlarge"><b>Messwerte</b></h1>
      </div>

      <div className="w3-container"> 
        <div className="w3-cell w3-mobile w3-section w3-center">
          <SmallStats
            label="Temperatur"
            materialIcon="thermostat"
            value={temperature}
            unit="°C"
            timeString={temperatureTimestamp}
          />
        </div>
        <div className="w3-cell w3-mobile w3-section w3-center">
          <SmallStats
            label="Feuchtigkeit"
            materialIcon="water_drop"
            value={humidity}
            unit="%"
            timeString={humidityTimestamp}
          />
        </div>
    </div>
    
    <div className="w3-container w3-margin-top w3-padding-16">
      <SensorChart ref={(reference) => tempChart = reference } yLabel="Temperatur [°C]" xLabel="Messwert #" lineColor="rgba(48, 163, 29, 1.0)" />
    </div>

    <div className="w3-container w3-margin-top">
      <SensorChart ref={(reference) => humidityChart = reference } yLabel="rel. Feuchte [%]" xLabel="Messwert #" lineColor="rgba(25, 99, 128, 1.0)" />
    </div>

  </div>
  );
}
  
  export default SensorView;