import React from "react";
import { Line } from 'react-chartjs-2';


/*************************************************************/
//! SensorChart Properties
type Props = {
  yLabel: string;
  xLabel: string;
  lineColor: string;
}

/*************************************************************/
//! SensorChart State
type State = {
  yData: Array<number>;
}


/**
 * This component shows a line graph for two measured values.
 * The two values are plotted on two y axes.
 */
class SensorChart extends React.Component<Props, State> {
  
  /*************************************************************/
  //! Constructor
  constructor(props: Props) {
    super(props);
    this.state = {
      yData: Array<number>(),
    }
  }

  /*************************************************************/
  //! This is the chart data.
  //! Refer to https://www.chartjs.org/docs/3.3.2/general/data-structures.html
  chartData = {
    labels: [0,1,2,3],
    datasets: [
      {
        label: 'Temperature',
        data: [0],
        fill: false,
        backgroundColor: 'rgba(48, 163, 29, 1.0)',
        borderColor: 'rgba(48, 163, 29, 1.0)',
        yAxisID: 'yLeft',
      },
    ],
  };

  /*************************************************************/
  //! This are the chart options
  //! Refer to https://www.chartjs.org/docs/3.3.2/general/options.html
  chartOptions = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
          display: false
      }
    },
    stacked: false,
    scales: {
      yLeft: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: '',
          font: {
            size: 14,
            family: 'Signika',
          },
        },
      },
      x: {
        title: {
          display: true,
          text: '',
          font: {
            size: 14,
            family: 'Signika',
          },
        },
      }
    },
    animation: {
      duration: 0
    },
  };

  /**
   * Add a value to the data set, which is shown on the left y-axis
   * @param value The value to add to the dataset
   */
  dataAddValue(value: number) {
    this.state.yData.push(value);
    this.setState({yData: this.state.yData});
  }


  render() {

    // Get values from props
    const { yLabel, xLabel, lineColor } = this.props;

    // Update chart options with the properties we got above
    this.chartOptions.scales.x.title.text = xLabel;
    this.chartOptions.scales.yLeft.title.text = yLabel;
    this.chartData.datasets[0].label = yLabel;
    this.chartData.datasets[0].borderColor = lineColor;
    this.chartData.datasets[0].backgroundColor = lineColor;

    // Update chart data, display only the last 200 values
    this.chartData.datasets[0].data = this.state.yData.slice(-200);
    this.chartData.labels = Array.from(this.state.yData.keys()).slice(-200);

    return (
      <div style={{maxWidth: '800px'}}>
        <Line data={this.chartData} options={this.chartOptions} key={this.chartData.labels[this.chartData.labels.length - 1]} />
      </div>
    );
  }
}

export default SensorChart;
