import React from "react";
import { Redirect } from "react-router-dom";

// Route Views
import HomeView from "./views/HomeView";
import SensorView from "./views/SensorView";
import DatenschutzView from "./views/DatenschtzView";

const routes = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/home" />
  },
  {
    path: "/home",
    component: HomeView
  },
  {
    path: "/sensor",
    component: SensorView
  },
  {
    path: "/datenschutz",
    component: DatenschutzView
  }
];

export default routes;